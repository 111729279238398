<template>
  <section>
    <navi-bar>
      {{$t('front.common.board')}}
    </navi-bar>
    <div class="msgreadwrap">
      <div class="servicewritehead">
        <!--a class="betlistaddbtn gamebetmore">베팅내역첨부</a-->
        <input class="" type="file" />
      </div>
      <div class="servicewriteinput">
        <input type="text" :placeholder="$t('front.board.title')" v-model="title">

        <textarea :placeholder="$t('front.board.commentPlaceholder')" v-model="content"></textarea>
      </div>
    </div>

    <div class="sbtnwrap mb100">
      <a class="ok" @click="onClickCancel">{{$t('front.button.cancel')}}</a>
      <a class="all" @click="onClickSubmit">{{$t('front.board.reg')}}</a>
    </div>
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import BoardWrite from '@/views/member/board/write'

export default {
  name: 'board',
  components: {
    NaviBar
  },
  mixins: [BoardWrite]
}
</script>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style scoped>
input[type=file]::file-selector-button {width: 100px;height: 35px;background: #41b06c;color: #fff;font-weight: bold;border: 1px solid #41b06c;border-radius: 6px;display: inline-block;cursor: pointer;}

</style>
